import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { MainWidgetTextField } from 'views/common/components/UI/MainWidget';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import React from 'react';

export const StyledMainWidgetTextField = styled(MainWidgetTextField)(
  ({ theme }) => ({
    '& .MuiInputBase-input.MuiFilledInput-input': {
      paddingLeft: `${theme.spacing(6.2)} !important`,
    },
    '& .MuiInputAdornment-root': {
      display: 'none',
    },
    '& .MuiFilledInput-root': {
      '&.Mui-disabled': {
        backgroundColor: `${theme.palette.action.disabledBackground} !important`,
      },
    },
  }),
);

const newTheme = (theme) => createTheme({
  ...theme,
  components: {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontSize: theme.typography.pxToRem(14),
          fontWeight: 400,
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        weekDayLabel: {
          fontSize: theme.typography.pxToRem(14),
          fontWeight: 700,
        },
      },
    },
    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          display: 'none',
        },
      },
    },
  },
});

export const StyledMuiDatePicker = (props) => (
  <ThemeProvider theme={newTheme}>
    <MuiDatePicker {...props} />
  </ThemeProvider>
);
