import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { WidgetLocationField } from 'views/common/components/UI/MainWidget/WidgetLocationField';

const StyledGrid = styled(Grid)(({ theme }) => ({
  '&.rentalWidget': {
    alignItems: 'center',
  },
  '& .RWCheckBox': {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  '& .RWSearchNowButton': {
    lineHeight: 'unset',
    height: '100%',
  },
  '& .RWArrowIcon': {
    position: 'absolute',
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'center',
    width: theme.typography.pxToRem(35),
    height: theme.typography.pxToRem(35),
    top: '50%',
    transform: 'translateY(-50%)',
    right: theme.typography.pxToRem(-20),
    zIndex: 2,
    backgroundColor: theme.palette.common.white,
    border: `2px solid ${theme.palette.grey[200]}`,
    borderRadius: '2px',
    '& svg': {
      width: '100%',
      height: '100%',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));
export const ShiftedLocationFeild = styled(WidgetLocationField)(
  ({
    theme, vertical, SHIFT_AMOUNT, selectedLocationId,
  }) => ({
    backgroundColor: selectedLocationId
      ? theme.palette.common.white
      : theme.palette.grey[50],
    '& .IconedBoxContainer': {
      [theme.breakpoints.up('md')]: {
        left: vertical ? null : SHIFT_AMOUNT,
      },
      '& .MuiFilledInput-root': {
        backgroundColor: selectedLocationId
          ? theme.palette.common.white
          : theme.palette.grey[50],
      },
      '& .Mui-error': {
        '&:before, :after': {
          left: vertical ? null : `-${SHIFT_AMOUNT}px`,
        },
      },
    },
  }),
);
export default StyledGrid;
