export const RENTAL_WIDGET = 'rentalWidget';
export const TOUR_WIDGET = 'tourWidget';
export const RENTL_ENTRY = 'rentalsEntry';
export const TOUR_ENTRY = 'toursEntry';

export const LOCATION_TYPE = {
  PICKUP: 'pickup',
  DROPOFF: 'dropoff',
};

export const MAX_DISTANCE = 100;
export const RENTAL_WIDGET_DATE_FORMAT = 'YYYY-MM-DD';
export const RENTAL_WIDGET_FIELDS = {
  PICKUP_LOCATION_ID: 'pickup_location_id',
  DROPOFF_LOCATION_ID: 'dropoff_location_id',
  PICKUP_DATE: 'pickup_date',
  DROPOFF_DATE: 'dropoff_date',
  PICKUP_TIME_SLOT: 'pickup_time_slot',
  DROPOFF_TIME_SLOT: 'dropoff_time_slot',
  VEHICLES_COUNT: 'vehicles_count',
};

export const TOUR_WIDGET_FIELDS = {
  COUNTRY: 'country',
  CITY: 'city',
  TOUR: 'tour',
};

export const TOUR_TYPES = {
  GUIDED: 1,
  SELF: 2,
};

export const ERRORS_PREFIX = 'reservation_widget_';
