import React from 'react';
import dayjs from 'dayjs';
import { Grid, MenuItem } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DateRangeIcon from '@mui/icons-material/DateRange';
import {
  MainWidgetDatePicker,
  IconedBox,
} from 'views/common/components/UI/MainWidget';
import {
  LOCATION_TYPE,
  RENTAL_WIDGET_FIELDS,
  ERRORS_PREFIX,
} from 'lib/utils/mainWidgetConstants';
import isBetween from 'dayjs/plugin/isBetween';
import StyledGrid, {
  SelectMainWidgetTextField,
} from 'views/common/components/UI/MainWidget/WidgetDateTimeField/WidgetDateTimeField.styles';

dayjs.extend(isBetween);

const WidgetDateTimeField = (props) => {
  const {
    pickup,
    vertical,
    date,
    handleDateTimeChange,
    time,
    timeList = [],
    label,
    minDate,
    maxDate,
    shouldDisableDate,
    errors,
    bordered,
    disabled,
    openWidgetDateTimeField,
    resetOpenWidgetDateTimeField,
  } = props;
  const getDateFieldType = () => (pickup === LOCATION_TYPE.PICKUP
    ? RENTAL_WIDGET_FIELDS.PICKUP_DATE
    : RENTAL_WIDGET_FIELDS.DROPOFF_DATE);

  const getTimeSlotsFieldType = () => (pickup === LOCATION_TYPE.PICKUP
    ? RENTAL_WIDGET_FIELDS.PICKUP_TIME_SLOT
    : RENTAL_WIDGET_FIELDS.DROPOFF_TIME_SLOT);

  return (
    <StyledGrid container xs={12} bordered={vertical || bordered}>
      <Grid
        className="WidgetDateField"
        item
        xs={6}
        sm={7}
        lg={vertical ? 7 : 8}>
        <IconedBox
          className="WDTFDate"
          Icon={DateRangeIcon}
          size={24}
          error={!!errors[`${ERRORS_PREFIX}${getDateFieldType()}`]}>
          <MainWidgetDatePicker
            label={label}
            value={dayjs(date || null)}
            fieldType={getDateFieldType()}
            onChange={(value) => {
              if (
                value
                && !dayjs(date).isSame(value)
                && !shouldDisableDate(pickup, value)
                && dayjs(value).isBetween(
                  dayjs(minDate),
                  dayjs(maxDate),
                  'day',
                  '[]',
                )
              ) {
                handleDateTimeChange(getDateFieldType(), value);
              }
            }}
            minDate={dayjs(minDate)}
            maxDate={dayjs(maxDate)}
            bordered={vertical || bordered}
            // eslint-disable-next-line no-shadow
            shouldDisableDate={(date) => shouldDisableDate(pickup, date)}
            id={`${ERRORS_PREFIX}${getDateFieldType()}`}
            error={!!errors[`${ERRORS_PREFIX}${getDateFieldType()}`]}
            disabled={disabled}
            openMainWidgetDatePicker={openWidgetDateTimeField}
            resetOpenWidgetDateTimeField={resetOpenWidgetDateTimeField}
          />
        </IconedBox>
      </Grid>
      <Grid item xs={6} sm={5} lg={vertical ? 5 : 4}>
        <IconedBox
          className="WDTFTime"
          Icon={AccessTimeIcon}
          size={24}
          error={!!errors[`${ERRORS_PREFIX}${getTimeSlotsFieldType()}`]}>
          <SelectMainWidgetTextField
            select
            fullWidth
            bordered={vertical || bordered}
            id={`${ERRORS_PREFIX}${getTimeSlotsFieldType()}`}
            error={!!errors[`${ERRORS_PREFIX}${getTimeSlotsFieldType()}`]}
            /* helperText={
                errors[`${ERRORS_PREFIX}${getTimeSlotsFieldType()}`] || ''
              } */
            value={time || ''}
            onChange={(e) => handleDateTimeChange(getTimeSlotsFieldType(), e.target.value)}
            variant="filled"
            disabled={disabled}
            SelectMainWidgetTextField
            className="WidgetInput">
            {timeList.map((option) => (
              <MenuItem
                key={option}
                value={option}
                sx={{ textTransform: 'uppercase' }}>
                {option}
              </MenuItem>
            ))}
          </SelectMainWidgetTextField>
        </IconedBox>
      </Grid>
    </StyledGrid>
  );
};

export { WidgetDateTimeField };
