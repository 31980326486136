import {
  Grid, styled, Chip, alpha, MenuItem, Box,
} from '@mui/material';
import {
  MainWidgetTextField,
  IconedBox,
} from 'views/common/components/UI/MainWidget';

export const StyledChip = styled(Chip)(({ theme }) => ({
  position: 'absolute',
  bottom: '50%',
  transform: 'translateY(50%)',
  right: theme.spacing(4),
  height: theme.spacing(3),
  backgroundColor: alpha(theme.palette.error.main, 0.1),
  color: theme.palette.error.main,
  fontWeight: 500,
  fontSize: theme.spacing(1.5),
  '& .VCOfferIcon': {
    fontSize: theme.spacing(1.5),
    color: theme.palette.error.main,
  },
  '&.showDESKTOP': {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  '&.showTABLET': {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
}));
export const StyledBox = styled(Box)(({ theme }) => ({
  '&.VCDoneBtnContainer': {
    padding: theme.spacing(1, 2),
    position: 'fixed',
    bottom: 0,
    right: 0,
    left: 0,
  },
  '&.VCloseBtnContainer': {
    textAlign: 'end',
    padding: theme.spacing(0, 2),
    '& .VCloseBtn': {
      padding: 0,
    },
  },
}));
export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(1, 2),
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.spacing(0.5),
  border: `1px solid ${theme.palette.grey[100]}`,
  '& .VCLableAndDisc': {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  '& .MuiFormControlLabel-label': {
    color: theme.palette.grey[600],
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
  },
  '& .VCItemDiscount': {
    display: 'inline-block',
    backgroundColor: theme.palette.error.A000,
    color: theme.palette.error.main,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    height: 'fit-content',
  },
  '&.VCItemTitle': {
    color: theme.palette.grey[600],
    opacity: 1,
    padding: 0,
    border: 0,
    alignItems: 'flex-start',
  },
  '& .VCCounterItem': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0, 0, 0.5, 0.5),
    backgroundColor: theme.palette.grey.A000,
  },
  '& .VCCounterBike': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  '& .VCBikeIcon': {
    width: theme.spacing(2),
    height: theme.spacing(2),
    '& path': {
      fill: theme.palette.secondary.light,
    },
  },
  '& .VCBikeText': {
    color: theme.palette.secondary.light,
    lineHeight: theme.spacing(3),
  },
  '&.VCItemSelected': {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.A000,
    '& .VCItemDiscount': {
      backgroundColor: 'transparent',
    },
  },
  '&.VCCounterMenuItme': {
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    padding: 0,
    '& .VCLableAndDisc': {
      padding: theme.spacing(0.5, 1),
    },
    '&.VCItemSelected': {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.common.white,
      '& .VCCounterItem': {
        backgroundColor: `${theme.palette.primary.A000} !important`,
      },
    },
    '& .VCItemDiscount': {
      backgroundColor: theme.palette.error.A000,
    },
  },
}));
export const StyledIconedBox = styled(IconedBox)(({ theme, error }) => ({
  '& .IBIcon': {
    top: error ? '30%' : '50%',
  },
  position: 'relative',
  '&.VCInputWithBorder': {
    border: `1px solid ${theme.palette.orange[900]}`,
  },
}));

export const StyledMainWidgetTextField = styled(MainWidgetTextField)(
  ({ theme, bordered }) => ({
    '& .MuiInputBase-input.MuiFilledInput-input': {
      padding: `${theme.spacing(1, 1, 1, 5.6)} !important`,
    },
    border: bordered ? `1px solid ${theme.palette.grey[100]}` : null,
    [theme.breakpoints.down('md')]: {
      border: `1px solid ${theme.palette.grey[100]}`,
    },
  }),
);

export const BorederedMainWidgetTextField = styled(MainWidgetTextField)(
  ({ theme, bordered }) => ({
    border: bordered ? `1px solid ${theme.palette.grey[100]}` : null,
    [theme.breakpoints.down('md')]: {
      border: `1px solid ${theme.palette.grey[100]}`,
    },
    '& .MuiFilledInput-root': {
      '&.Mui-disabled': {
        backgroundColor: `${theme.palette.action.disabledBackground} !important`,
      },
      '& .MuiSelect-select': {
        minHeight: theme.spacing(3.25),
      },
    },
    '& .VCInputValue': {
      color: theme.palette.secondary.dark,
      option: 0.9,
      fontSize: theme.spacing(1.75),
    },
  }),
);

export const StyledGrid = styled(Grid)(({ theme, bordered }) => ({
  border: bordered ? `1px solid ${theme.palette.grey[100]}` : null,
  backgroundColor: theme.palette.common.white,
  '& .VCCloseIcon': {
    borderLeft: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 0,
    padding: '0',
    width: theme.typography.pxToRem(44),
    height: '100%',
  },
}));
