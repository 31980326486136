import { Box, Menu } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const StyledBox = styled(Box)(({ theme, vertical }) => ({
  width: vertical ? '100%' : 'fit-content',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  '& .WTButton': {
    justifyContent: vertical ? 'space-between' : 'initial',
    color: theme.palette.common.white,
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: 'inherit',
    },
    [theme.breakpoints.down('md')]: {
      color: theme.palette.grey[900],
      justifyContent: 'space-between',
    },
  },
  '& .WTTitle': {
    fontSize: theme.typography.pxToRem(24),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  '& .WTMoreText': {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
}));
export const StyledMenu = styled((props) => <Menu {...props} />)(
  ({ width }) => ({
    '& .MuiPaper-root': {
      textTransform: 'uppercase',
      width,
      '& .menuTitle': {
        opacity: 1,
        textTransform: 'initial',
        whiteSpace: 'break-spaces',
      },
    },
  }),
);

export default StyledBox;
