import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import { RENTAL_WIDGET } from 'lib/utils/mainWidgetConstants';
import { useSelector } from 'react-redux';
import { MainWidgetForCrawlers } from 'views/common/components/UI/MainWidgetForCrawlers';

const MainWidget = dynamic(() => import('views/common/components/UI/MainWidget/MainWidget').then(
  (file) => file.MainWidget,
));

const MainWidgetWrapper = (props) => {
  const { widgetType, whiteLabelWidget } = props;
  const [widgetInfo, setWidgetInfo] = useState({
    type: widgetType || RENTAL_WIDGET,
  });
  const isCrawler = useSelector((state) => state.globalData.isCrawler);
  // eslint-disable-next-line no-shadow
  const handleWidgetMenuSelect = (widgetType, tourType) => {
    setWidgetInfo({
      type: widgetType,
      tourType,
    });
  };
  return (
    <>
      {isCrawler ? (
        <MainWidgetForCrawlers
          {...props}
          widgetInfo={widgetInfo}
          handleWidgetMenuSelect={handleWidgetMenuSelect}
          whiteLabelWidget={whiteLabelWidget}
          vertical
        />
      ) : (
        <MainWidget
          widgetInfo={widgetInfo}
          handleWidgetMenuSelect={handleWidgetMenuSelect}
          whiteLabelWidget={whiteLabelWidget}
          {...props}
        />
      )}
    </>
  );
};

export { MainWidgetWrapper };
