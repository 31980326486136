import React, { useState, useMemo, useRef } from 'react';
import { Button, MenuItem } from '@mui/material';
import Typography500 from 'views/common/components/UI/Typography500';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'next-i18next';
import {
  RENTAL_WIDGET,
  TOUR_WIDGET,
  TOUR_TYPES,
} from 'lib/utils/mainWidgetConstants';
import { Typography700 } from 'views/common/components/UI/Typography700';
import StyledBox, {
  StyledMenu,
} from 'views/common/components/UI/MainWidget/WidgetTitle/WidgetTitle.styles';

const WidgetTitle = (props) => {
  const {
    widgetInfo,
    titleColor,
    handleWidgetMenuSelect,
    className,
    vertical,
    renderAllProductOptions = true,
  } = props;

  const { t } = useTranslation('fe_er_common_main_widget');

  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRef = useRef();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const widgetTitles = useMemo(
    () => (renderAllProductOptions
      ? [
        {
          title: t('fe_er_common_main_widget:rent_motorcycle'),
          type: RENTAL_WIDGET,
        },
        {
          title: t('fe_er_common_main_widget:all_motorcycle_tours'),
          type: TOUR_WIDGET,
        },
        {
          title: t('fe_er_common_main_widget:guided_motorcycle_tours'),
          type: TOUR_WIDGET,
          tourType: TOUR_TYPES.GUIDED,
        },
        {
          title: t('fe_er_common_main_widget:self_drive_motorcycle_tours'),
          type: TOUR_WIDGET,
          tourType: TOUR_TYPES.SELF,
        },
      ]
      : [
        {
          title: t('fe_er_common_main_widget:rent_motorcycle'),
          type: RENTAL_WIDGET,
        },
      ]),
    [renderAllProductOptions],
  );

  const widgetTitle = useMemo(() => {
    switch (widgetInfo.type) {
      case TOUR_WIDGET:
        switch (widgetInfo.tourType) {
          case TOUR_TYPES.SELF:
            return widgetTitles[3].title;
          case TOUR_TYPES.GUIDED:
            return widgetTitles[2].title;
          default:
            return widgetTitles[1].title;
        }
      default:
        return widgetTitles[0].title;
    }
  }, [widgetInfo]);

  const open = Boolean(anchorEl);

  return (
    <StyledBox className={`${className || ''} WTContainer`} vertical={vertical}>
      <Button
        fullWidth
        disableRipple
        disableFocusRipple
        id="mainWiggetButton"
        aria-controls={open ? 'title-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        ref={buttonRef}
        disabled={!renderAllProductOptions}
        className="WTButton">
        <Typography700
          component="p"
          variant="h3"
          className="WTTitle"
          sx={{ color: titleColor && `${titleColor}!important` }}>
          {widgetTitle}
        </Typography700>
        {renderAllProductOptions && (
          <Typography500
            component="span"
            className="WTMoreText"
            sx={{ color: titleColor && `${titleColor}!important` }}>
            {t('fe_er_common_main_widget:more')}
            <KeyboardArrowDownIcon />
          </Typography500>
        )}
      </Button>
      <StyledMenu
        id="title-menu"
        anchorEl={anchorEl}
        open={open}
        width={buttonRef?.current?.clientWidth}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <MenuItem disabled className="menuTitle">
          <Typography500 component="p" variant="h4">
            {t('fe_er_common_main_widget:how_do_you_want_to_ride')}
          </Typography500>
        </MenuItem>
        {widgetTitles.map((titleItem) => (
          <MenuItem
            key={titleItem.title}
            onClick={() => {
              handleWidgetMenuSelect(titleItem.type, titleItem.tourType);
              handleClose();
            }}>
            {titleItem.title}
          </MenuItem>
        ))}
      </StyledMenu>
    </StyledBox>
  );
};

export { WidgetTitle };
