import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import {
  Box,
  Autocomplete,
  Typography,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import {
  LOCATION_TYPE,
  RENTAL_WIDGET_FIELDS,
  ERRORS_PREFIX,
} from 'lib/utils/mainWidgetConstants';
import {
  AutoCompleteMenu,
  MainWidgetTextField,
} from 'views/common/components/UI/MainWidget';
import StyledBox, {
  StyledIconedBox,
} from 'views/common/components/UI/MainWidget/WidgetLocationField/WidgetLocationField.styles';

const WidgetLocationField = (props) => {
  const {
    selectedLocationId,
    pickup,
    locationsList = [],
    label,
    placeholder,
    handleInputChange,
    handleLocationChange,
    handleRenderAllWidget,
    loading,
    errors,
    disabled,
    className,
    shiftAmount,
    vertical,
    bordered,
    isDealWidget,
  } = props;
  const { t } = useTranslation('fe_er_common_main_widget');

  const getFieldType = () => (pickup === LOCATION_TYPE.PICKUP
    ? RENTAL_WIDGET_FIELDS.PICKUP_LOCATION_ID
    : RENTAL_WIDGET_FIELDS.DROPOFF_LOCATION_ID);
  const [isFocused, setIsFocused] = useState('');
  const autoCompleteInputRef = useRef();
  const selectedLocation = useMemo(
    () => locationsList.find(
      (location) => location.id && location.id === selectedLocationId,
    ) || null,
    [selectedLocationId, locationsList],
  );

  const placeHolder = useMemo(() => {
    if (isFocused && selectedLocation) {
      return `${selectedLocation.display_name}, ${selectedLocation.region_name}`;
    }
    return placeholder;
  }, [selectedLocation, isFocused, placeholder]);

  const fieldValue = useMemo(() => {
    if (isFocused) return null;
    return selectedLocation;
  }, [isFocused, selectedLocation]);
  return (
    <StyledBox
      className={className}
      bordered={vertical || bordered}
      error={!!errors[`${ERRORS_PREFIX}${getFieldType()}`]}>
      <StyledIconedBox
        className="WidgeLocaionField"
        Icon={LocationOnIcon}
        error={!!errors[`${ERRORS_PREFIX}${getFieldType()}`]}>
        <Autocomplete
          value={fieldValue}
          options={loading ? [] : locationsList}
          filterOptions={(x) => x}
          getOptionLabel={(option) => `${option.display_name}, ${option.region_name}`}
          id={`${ERRORS_PREFIX}${getFieldType()}`}
          error={!!errors[`${ERRORS_PREFIX}${getFieldType()}`]}
          // helperText={errors[`${ERRORS_PREFIX}${getFieldType()}`] || ''}
          popupIcon={null}
          className="WidgetInput"
          autoComplete
          disableClearable
          includeInputInLis
          componentsProps={{
            paper: { shiftAmount },
          }}
          PaperComponent={AutoCompleteMenu}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onInputChange={(e, value, reason) => {
            if (reason !== 'reset') handleInputChange(pickup, value);
          }}
          disabled={disabled}
          onChange={(e, value) => {
            setIsFocused(true);
            handleLocationChange(getFieldType(), value?.id);
            autoCompleteInputRef.current.blur();
          }}
          onOpen={() => handleRenderAllWidget && handleRenderAllWidget(true)}
          loading={loading}
          renderOption={(params, option) => (
            <li {...params}>
              <Box>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: `${option.display_name}, <b>${option.region_name}</b>`,
                  }}
                />
                {option.distance >= 0 && (
                  <Typography>
                    {`${option.distance} ${t(
                      'fe_er_common_main_widget:miles_away',
                    )} ${option.formattedAddress}`}
                  </Typography>
                )}
              </Box>
            </li>
          )}
          renderInput={(params) => (
            <>
              <InputLabel className="WidgetInputLabel">{label}</InputLabel>
              <MainWidgetTextField
                {...params}
                inputRef={autoCompleteInputRef}
                placeholder={placeHolder}
                isFocused={isFocused}
                variant="filled"
                error={!!errors[`${ERRORS_PREFIX}${getFieldType()}`]}
                // helperText={errors[`${ERRORS_PREFIX}${getFieldType()}`] || ''}
              />
            </>
          )}
        />
      </StyledIconedBox>
      {isDealWidget
        && pickup === LOCATION_TYPE.DROPOFF
        && errors[`${ERRORS_PREFIX}${getFieldType()}`] && (
          <FormHelperText error>
            {t('fe_er_common_main_widget:dropoff_location_error')}
          </FormHelperText>
      )}
    </StyledBox>
  );
};

export { WidgetLocationField };
