import { ButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  '& .VCCountNum': {
    width: theme.spacing(3),
    fontSize: theme.spacing(1.75),
    lineHeight: theme.spacing(3),
    textAlign: 'center',
    margin: theme.spacing(0, 1),
  },
  '& .VCBtn': {
    width: theme.spacing(3),
    height: theme.spacing(3),
    minWidth: 'unset',
    borderRadius: '50% !important',
    padding: 0,
    borderColor: `${theme.palette.secondary.main} !important`,
    '&:active, &:hover': {
      borderColor: `${theme.palette.primary.main} !important`,
      color: theme.palette.primary.main,
    },
    '&.Mui-disabled': {
      borderColor: `${theme.palette.grey[200]} !important`,
    },
    '& svg': {
      fontSize: theme.spacing(2),
    },
  },
}));

export default StyledButtonGroup;
