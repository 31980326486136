/* eslint-disable no-restricted-globals */
import React, { useState, useMemo } from 'react';
import Bike from 'views/common/components/UI/Icons/Bike';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import {
  RENTAL_WIDGET_FIELDS,
  ERRORS_PREFIX,
} from 'lib/utils/mainWidgetConstants';
import { useTranslation } from 'next-i18next';
import {
  StyledIconedBox,
  BorederedMainWidgetTextField,
  StyledChip,
  StyledMenuItem,
  StyledBox,
} from 'views/common/components/UI/MainWidget/VehicleCount/VehicleCount.styles';
import Typography500 from 'views/common/components/UI/Typography500';
import {
  Box,
  FormControlLabel,
  Typography,
  Button,
  useTheme,
  IconButton,
} from '@mui/material';
import Radio from '@mui/material/Radio';
import { VehicalCounter } from 'views/common/components/UI/MainWidget';
import CloseIcon from '@mui/icons-material/Close';

const VehicleCount = (props) => {
  const {
    errors,
    handleFormDataChange,
    formData,
    motorcycleList,
    vertical,
    bordered,
    disableVehicleCount,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const [openMenu, setOpenMenu] = useState(false);

  const savedValue = useMemo(
    () => motorcycleList.find(
      (motorcycle) => +motorcycle.number === formData.reservation.vehicles_count
          || (+motorcycle.number === 6
            && formData.reservation.vehicles_count >= 6),
    )?.discount,
    [motorcycleList, formData.reservation.vehicles_count],
  );

  const menuProps = useMemo(
    () => ({
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      PaperProps: {
        sx: {
          [theme.breakpoints.down('md')]: {
            top: '0 !important',
            bottom: '0 !important',
            height: '100% !important',
            right: '0 !important',
            left: '0 !important',
            maxWidth: 'unset !important',
            maxHeight: 'unset !important',
            borderRadius: 0,
          },
        },
      },
      MenuListProps: {
        style: {
          [theme.breakpoints.down('md')]: {
            postion: 'relative',
            height: '100%',
          },
        },
      },
    }),
    [],
  );

  const handleOpenMenu = () => {
    setOpenMenu(true);
  };
  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const isOptionSelected = (option) => +option.number === formData.reservation.vehicles_count
    || (formData.reservation.vehicles_count >= 6 && +option.number === 6);

  return (
    <StyledIconedBox
      className={savedValue && 'VCInputWithBorder'}
      Icon={Bike}
      error={!!errors[`${ERRORS_PREFIX}vehicles_count`]}>
      <BorederedMainWidgetTextField
        SelectProps={{
          MenuProps: menuProps,
          open: openMenu,
          onClose: handleCloseMenu,
          onOpen: handleOpenMenu,
          renderValue: (value) => (
            <Typography className="VCInputValue">
              {value}
              {' '}
              {value > 1
                ? t('fe_er_common_main_widget:motorcycles')
                : t('fe_er_common_main_widget:motorcycle')}
            </Typography>
          ),
        }}
        select
        bordered={vertical || bordered}
        fullWidth
        disabled={disableVehicleCount}
        id={`${ERRORS_PREFIX}vehicles_count`}
        error={!!errors[`${ERRORS_PREFIX}vehicles_count`]}
        helperText={errors[`${ERRORS_PREFIX}vehicles_count`] || ''}
        value={formData.reservation?.vehicles_count}
        variant="filled"
        className="WidgetInput"
        disableCloseOnSelect>
        <>
          <StyledBox px={2} py={1} className="VCloseBtnContainer showMOBILE">
            <IconButton className="VCloseBtn" onClick={handleCloseMenu}>
              <CloseIcon />
            </IconButton>
          </StyledBox>
          <StyledMenuItem className="VCItemTitle" disabled>
            <Typography500 variant="h5">
              {t('fe_er_common_main_widget:select_number_motorcycles')}
            </Typography500>
          </StyledMenuItem>
          {motorcycleList.map((option) => (
            <StyledMenuItem
              className={`${option.number === 6 && 'VCCounterMenuItme'} ${
                isOptionSelected(option) && 'VCItemSelected'
              } `}
              key={+option.number}
              value={+option.number}
              onChange={(e) => {
                handleFormDataChange(
                  RENTAL_WIDGET_FIELDS.VEHICLES_COUNT,
                  +e.target.value,
                );
              }}>
              <Box
                className="VCLableAndDisc"
                onClick={() => {
                  handleFormDataChange(
                    RENTAL_WIDGET_FIELDS.VEHICLES_COUNT,
                    +option.number,
                  );
                  if (+option.number < 6) {
                    handleCloseMenu();
                  }
                }}>
                <FormControlLabel
                  value={+option.number}
                  checked={isOptionSelected(option)}
                  control={<Radio size="small" />}
                  label={`${+option.number === 6 ? '6+' : +option.number} ${
                    +option.number > 1
                      ? t('fe_er_common_main_widget:motorcycles')
                      : t('fe_er_common_main_widget:motorcycle')
                  } `}
                />
                {option.discount && (
                  <Box className="VCItemDiscount">
                    <Typography500 variant="h6">
                      {t('fe_er_common_main_widget:off_value', {
                        value: option.discount,
                      })}
                    </Typography500>
                  </Box>
                )}
              </Box>

              {+option.number === 6 && (
                <Box className="VCCounterItem">
                  <Box className="VCCounterBike">
                    <Bike className="VCBikeIcon" />
                    <Typography500
                      component="span"
                      variant="caption"
                      className="VCBikeText">
                      {}
                      {t('fe_er_common_main_widget:motorcycle_s', {
                        number:
                          formData.reservation.vehicles_count >= 6
                            ? formData.reservation.vehicles_count
                            : 6,
                      })}
                    </Typography500>
                  </Box>
                  <VehicalCounter
                    vehicleCount={formData.reservation.vehicles_count}
                    handleFormDataChange={handleFormDataChange}
                  />
                </Box>
              )}
            </StyledMenuItem>
          ))}
          <StyledBox px={2} py={1} className="VCDoneBtnContainer showMOBILE">
            <Button fullWidth variant="contained" onClick={handleCloseMenu}>
              {t('fe_er_common_main_widget:done')}
            </Button>
          </StyledBox>
        </>
      </BorederedMainWidgetTextField>
      {savedValue && (
        <>
          <StyledChip
            className="showDESKTOP"
            icon={<LocalOfferIcon className="VCOfferIcon" />}
            label={t('fe_er_common_main_widget:save_up_to', {
              value: savedValue,
            })}
            onClick={handleOpenMenu}
          />
          <StyledChip
            className="showTABLET"
            icon={<LocalOfferIcon className="VCOfferIcon" />}
            label={t('fe_er_common_main_widget:up_to', { value: savedValue })}
            onClick={handleOpenMenu}
          />
        </>
      )}
    </StyledIconedBox>
  );
};

export { VehicleCount };
