import { Box, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: '#fff7f7',
  color: theme.palette.error.main,
  padding: theme.spacing(1, 2),
  lineHeight: theme.spacing(1.5),
  boxShadow: '0 0 0 2px rgb(176 0 32 / 24%)',
  border: '0px solid rgba(0,0,0,0.25)',
  borderRadius: '1px ',
  marginTop: theme.spacing(1),
  '& .WBEsmallFontSize': {
    fontSize: theme.typography.pxToRem(14),
  },

  '& .WBECloseButton': {
    padding: theme.spacing(0.5),
  },
}));

const WidgetNotificationBox = React.memo((props) => {
  const { notificationsList, title } = props;
  const [openError, setOpenError] = useState();

  const handleCloseErrorClick = () => setOpenError(false);

  useEffect(() => {
    setOpenError(!!notificationsList);
  }, [notificationsList]);
  return (
    <>
      {openError && notificationsList && (
        <StyledBox
          alignItems={notificationsList.length > 1 ? 'flex-start' : 'center'}>
          <Box>
            {title && (
              <Typography className="WBEsmallFontSize">{title}</Typography>
            )}
            {notificationsList.map((baseError) => (
              <Typography className="WBEsmallFontSize">
                {title && (
                  <Typography className="WBEsmallFontSize" component="span">
                    &gt;
                    {' '}
                  </Typography>
                )}
                {' '}
                <Typography
                  component="span"
                  className="WBEsmallFontSize"
                  dangerouslySetInnerHTML={{
                    __html: baseError,
                  }}
                />
              </Typography>
            ))}
          </Box>
          <IconButton
            className="WBECloseButton"
            onClick={handleCloseErrorClick}>
            <CloseIcon />
          </IconButton>
        </StyledBox>
      )}
    </>
  );
});

export { WidgetNotificationBox };
