import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconedBox } from 'views/common/components/UI/MainWidget/IconedBox';

const StyledBox = styled(Box)(({ theme, bordered }) => ({
  border: bordered ? `1px solid ${theme.palette.grey[100]}` : null,
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    border: `1px solid ${theme.palette.grey[100]}`,
  },
}));

export const StyledIconedBox = styled(IconedBox)(({ theme, error }) => ({
  '& .WidgetInputLabel': {
    position: 'absolute',
    left: theme.spacing(6.6),
    fontSize: theme.typography.pxToRem(13),
    color: error ? theme.palette.error.main : theme.palette.grey[200],
    top: theme.typography.pxToRem(6),
    zIndex: 1,
  },
}));

export default StyledBox;
